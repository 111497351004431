import React, { useCallback, useState } from "react";
import { useDrag } from "react-dnd";
import { AllBloxes, BloxTypes } from "../Data/BloxSchema/base-blox";
import { Draggables } from "../Data/enums";
import { Column, Row } from "../Layout/layouts";
import { BloxImage, BloxImageProps } from "./BloxImage";
import { BetweenBlox } from "./BetweenBlox";
import { Stack } from "./Stack";
import { ContextMenu, Divider, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { BloxDialog } from "../dialogs/BloxDialog";
import { ExportSVGDialog } from "../dialogs/ExportSVGDialog";
import { ReportProblemDialog } from "../dialogs/ReportProblemDialog";
import { StateAndSetter } from "../hooks/state/parameter-context";
import { BloxInsertItem } from "./BloxDraggable";
import { ModuleInsertItem } from "./ModuleDraggable";

export interface ProcessBloxProps {
    copiedBloxState: StateAndSetter<AllBloxes | null>;
    bloxData: AllBloxes; // data for blox in this step
    bloxSVG: JSX.Element;
    processBloxes: AllBloxes[];
    handleInsertBlox?: (item: BloxInsertItem) => void;
    handleInsertModule?: (item: ModuleInsertItem) => void;
    onDelete?: (id: string) => void;
    onClickBlox: () => void;
    handleSectionClick?: () => void;
    showDropTarget: boolean;
    hideStack: boolean;
    leftMargin: boolean;
    isSelected: boolean;
    
}

export const ProcessBlox: React.FC<ProcessBloxProps> = ({copiedBloxState, handleSectionClick, processBloxes, bloxData, bloxSVG, handleInsertBlox, handleInsertModule, onDelete, onClickBlox, leftMargin, showDropTarget, hideStack, isSelected}) => {
    const [isBloxDialogOpen, setIsBloxDialogOpen] = useState(false);
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const isStartBlox = bloxData.bloxType === BloxTypes.StartBlox;

    const bloxIdx = processBloxes.findIndex(blox => blox.id === bloxData.id)

    const handleOpenDialog = useCallback(() => {setIsBloxDialogOpen(true)}, [setIsBloxDialogOpen]);

    const canDrag = !isStartBlox && !!handleInsertBlox;
    const [isExportSVGDialogOpen, setIsExportSVGDialogOpen] = useState(false);

    const closeDialogCallback = useCallback(() => setIsExportSVGDialogOpen(false), []);

    const [{ isDragging }, dragBlox] = useDrag(() => ({
        type: Draggables.Blox,
        item: { bloxType: bloxData.bloxType, id: bloxData.id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
        canDrag: () => canDrag
    }), [bloxData, canDrag]);

    const [copiedBlox, setCopiedBlox] = copiedBloxState;

    const renderMenu = () => (
        <Menu>
            <MenuItem icon={IconNames.Duplicate} text="Copy Blox" onClick={() => { setCopiedBlox(bloxData) }} />
            <MenuItem icon="plus" text="Save to Library" onClick={handleOpenDialog} />
            <MenuItem icon="export" text="Export Image" onClick={() => setIsExportSVGDialogOpen(true)} />
            <MenuItem
                icon={IconNames.ERROR}
                text="Report Image Problem"
                onClick={() => setIsReportDialogOpen(true)}
            />
            <Divider />
            <MenuItem icon="delete" text="Delete" onClick={() => onDelete && onDelete(bloxData.id)} intent="danger" />
        </Menu>
    );

    const bloxImageProps: BloxImageProps = {
        selected: isSelected,
        bloxType: bloxData.bloxType,
        displayName: bloxData.name,
        stepNumber: bloxIdx + 1,
        noDrag: !canDrag,
        toolName: bloxData.toolName ?? undefined,
        moduleId: bloxData.moduleId ?? undefined,
        moduleName: bloxData.moduleName ?? undefined
    };

    return (
        <>
            <Row>
                <Column onClick={onClickBlox} key={bloxData.id} style={{marginLeft: leftMargin ? '20px' : '0px', marginBottom: 'auto', marginTop: 'auto'}}>
                    <ContextMenu
                        disabled={isStartBlox}
                        content={
                            renderMenu()
                        }>
                        <Row style={{ "marginBottom": "10px", "marginTop": "10px" }}>

                            <div ref={dragBlox} style={{ opacity: isDragging ? 0.5 : 1 }}>
                                <BloxImage {...bloxImageProps} />
                            </div>
                        </Row>
                        {hideStack ? "" : <Stack bloxName={bloxData.name ? `${bloxIdx + 1}. ${bloxData.name}` : undefined} bloxSVG={bloxSVG} renderMenu={renderMenu} />}
                    </ContextMenu>
                </Column>
                <Column>
                    <Row style={{height: '100%', "marginTop": "10px" }}>
                        <BetweenBlox copiedBlox={copiedBlox} showDropTarget={showDropTarget} handleInsertBlox={handleInsertBlox} handleInsertModule={handleInsertModule} handleSectionClick={handleSectionClick}/>
                    </Row>
                </Column>
            </Row>
            <ExportSVGDialog filename={bloxData.name.trim()} isOpen={isExportSVGDialogOpen} closeDialog={closeDialogCallback} bloxes={processBloxes.slice(0, bloxIdx + 1)} />
            <BloxDialog isOpen={isBloxDialogOpen} setIsOpen={setIsBloxDialogOpen} bloxData={bloxData} />
            <ReportProblemDialog bloxes={processBloxes} bloxIdx={bloxIdx} isOpen={isReportDialogOpen} setIsOpen={(isOpen: boolean) => setIsReportDialogOpen(isOpen)} />
        </>
    );
}