import React from 'react';
import { Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DataProcessingInfo = 
  <List>
    <ListItem>
      <ListItemText
        primary="When you use our Services, you entrust us with your data. We take that trust to heart. You agree that we may process your data as described in our Privacy Policy and for no other purpose. We as humans can access your data for reasons including but not limited to:"
      />
    </ListItem>
    <ListItem>
      <ul>
        <li>
          <Typography variant="body1" component="span">
            1. <strong>To help you with support requests you make.</strong>
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
            2. <strong>On the rare occasions when an error occurs.</strong> We get automated alerts when errors occur. When we can fix the issue and restart automated processing without looking at any user data, we do. In rare cases, we have to look at a minimum amount of user data to fix the issue. In these rare cases, we aim to fix the root cause to prevent the errors from recurring.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
            3. <strong>To safeguard our products.</strong> We’ll look at logs and metadata as part of our work to ensure the security of your data and the Services as a whole.
          </Typography>
        </li>
      </ul>
    </ListItem>
  </List>;

const UseRestrictionInfo = <List>
<ListItem>
  <ListItemText
    primary="Adhere to these use restriction terms:"
  />
</ListItem>
<ListItem>
  <ul>
    <li>
      <Typography variant="body1" component="span">
        When you use any of our Services, you acknowledge that you may not:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" component="span">
            <strong>1. </strong>Collect or extract information and/or user data from accounts which do not belong to you.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>2. </strong>Circumvent, disable, or otherwise interfere with security-related features of the Services.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>3. </strong>Trick, defraud, or mislead us or other users, including but not limited to making false reports or impersonating another user.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>4. </strong>Upload or transmit (or attempt to upload or to transmit) viruses or any type of malware, or information collection mechanism, including 1×1 pixels, web bugs, cookies, or other similar devices.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>5. </strong>Interfere with, disrupt, or create an undue burden on the Services or the networks or the Services connected.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>6. </strong>Harass, annoy, intimidate, or threaten others, or any of our employees engaged in providing any portion of the Services to you.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>7. </strong>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography variant="body1" component="span">
          <strong>8. </strong>Use the Services in a manner inconsistent with any applicable laws or regulations.
          </Typography>
        </li>
      </ul>
    </li>
    <li>
      <Typography variant="body1" component="span">
        Accounts found to be in violation of any of the above are subject to cancellation without prior notice.
      </Typography>
    </li>
  </ul>
</ListItem>
</List>;

export default function TOSContent() {
  const sections = [
    {
      title: "Account Terms",
      content: [
        "You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. Account security guidelines follow the Terms & Conditions provided by Auth0 see link (https://auth0.com/docs/secure/data-privacy-and-compliance/gdpr)",
        "You are responsible for all content and activity that occurs under your account. This includes respecting plagiarism laws, accurate referencing of Process Flows and other Content duplicated from other Fabublox users or reproduced from literature or other sources of information.",
        "You must be a human. Accounts registered by \"bots\" or other automated methods are not permitted.",
        DataProcessingInfo,
        UseRestrictionInfo
      ]
    },
    {
      title: "Copyright and Content Ownership",
      content: [
        "All content posted on the Services must comply with U.S. copyright law.",
        "You give us a limited license to use the content posted by you and your users in order to provide the Services to you, but we claim no ownership rights over those materials (this includes images algorithmically generated by our Service based on your IP). All materials you submit to the Services remain yours.",
        "We ask that you attribute images generated on the Service in the form of \"This image was created using fabublox.com\"",
        "We do not pre-screen content, but we reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the Service.",
        "The Company or its licensors own all right, title, and interest in and to the Services, including all intellectual property rights therein, and you obtain no ownership rights in the Services as a result of your use. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company. You must request permission to use the Company's logos or any Service logos for promotional purposes. Please email hello@fabublox.com with requests to use logos. We reserve the right to rescind any permissions if you violate these Terms.",
        "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission of the Company."
      ]
    },
    {
      title: "Services Adaptations and API Terms",
      content: [
        "We offer Application Program Interfaces (\"API\"s) for some of our Services. We reserve the right to suspend or terminate these Services at any time without notice or reason. Any use of the API, including through a third-party product that accesses the Services, is bound by these Terms plus the following specific terms:",
        "1. You expressly understand and agree that we are not liable for any damages or losses resulting from your use of the API or third-party products that access data via the API.",
        "2. Third parties may not access and employ the API if the functionality is part of an application that remotely records, monitors, or reports a Service user's activity other than time tracking, both inside and outside the applications. The Company, in its sole discretion, will determine if an integration service violates this bylaw.",
        "3. Abuse or excessively frequent requests to the Services via the API may result in the temporary or permanent suspension of your account's access to the API. The Company, in its sole discretion, will determine abuse or excessive usage of the API. If we need to suspend your account's access, we will attempt to warn the account owner first. If your API usage could or has caused downtime, we may cut off access without prior notice."
      ]
    },
    {
      title: "Limitations Of Liability",
      content: [
        "You agree that the Company is not liable to you or to any third`` party for damages of any kind that result from the use of the Services, in ability to access data, or unauthorized access of your data or account. The Company is also not liable for damages of any kind related to actions of any third party that uses the Services, or any other consequences related to the Terms or Services."
      ]
    }
  ];

  const terminology = [
    { term: "\"Service(s)\" or \"Application(s)\"", definition: "refers to our websites, including fabublox.com and fabuknow.com" },
    { term: "\"The Company\", \"FabuBlox\", \"Ourselves\", \"We\", \"Our\" and \"Us\"", definition: "refers to Fabublox, Inc." },
    { term: "\"Client\", \"You\" and \"Your\"", definition: "refers to you, the person or entity logged into this website and compliant to the Company's terms and conditions." },
    { term: "\"Party\", \"Parties\", or \"Us\"", definition: "refers to both the Client and ourselves." },
    { term: "\"Processes\", \"Process flows\", \"Recipes\", or \"Content\"", definition: "refers to any content created in the FabuBlox Process Editor" }
  ];

  return (
    <Container maxWidth="md" className="tw-py-8">
      <Paper elevation={3} className="tw-p-8 tw-space-y-6 tw-rounded-xl">
        <Typography variant="h3" component="h1" className="tw-text-4xl tw-font-bold tw-mb-4">
          FabuBlox Terms of Service
        </Typography>
        <Typography variant="subtitle1" className="tw-text-gray-600">
          Last Updated: August 25, 2024
        </Typography>
        <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
          Welcome to FabuBlox!
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          These terms and conditions outline the rules and regulations for the use of FabuBlox, Inc.'s website and services, as defined below.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          When you use any FabuBlox products or services, you are agreeing to these latest Terms of Service ("Terms"). Violation of these terms may, at our discretion, result in us terminating your account.
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          We may update these Terms of Service at any time. Account owners will be notified of any significant changes via email.
        </Typography>

        <Typography variant="h6" component="h3" className="tw-font-bold tw-mt-6 tw-mb-2">
          Terminology
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          The following terminology applies to any of our Terms and Conditions, Privacy Statement, and Disclaimer Notice and Agreements:
        </Typography>
        <div className='tw-bg-slate-100 tw-rounded-xl'>
        <List>
          {terminology.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={item.term}
                secondary={item.definition}
                primaryTypographyProps={{ className: "tw-font-semibold" }}
              />
            </ListItem>
          ))}
        </List>
          
          </div>

        {sections.map((section, index) => (
          <Accordion key={index} className='tw-rounded-xl'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {section.content.map((item, itemIndex) => (
                  <ListItem key={itemIndex}>
                    {typeof item === 'string' ? (
                      <ListItemText primary={item} />
                    ) : (
                      item
                    )}                  
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h5" component="h2" className="tw-font-bold tw-mt-8 tw-mb-4">
          How to report abuse
        </Typography>
        <Typography variant="body1" className="tw-mb-4">
          Violations can be reported by emailing support@fabublox.com and should include detailed information about the account, the content or behavior you are reporting, and how you found it, including URLs or screenshots. If you need a secure file transfer, let us know and we will send you a link. We will not disclose your identity to anyone associated with the reported account.
        </Typography>

        <Divider className="tw-my-8" />

        <Typography variant="body1" className="tw-mb-4">
          If you have a question about any of these Terms, please contact our support team hello@fabublox.com
        </Typography>

        <Typography variant="body2" className="tw-mt-8 tw-text-gray-600">
          Attribution under CC BY 4.0: These terms & conditions were adapted from the Basecamp open-source policies / CC BY 4.0
        </Typography>
      </Paper>
    </Container>
  );
}