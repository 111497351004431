import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import { Column } from '../Layout/layouts';
import { Footer } from '../components/Footer';


import GridBackground from '../components/GridBackground';
import PrivacyPolicyContent from '../components/PrivacyPolicy';


export const PrivacyPolicy: React.FC = () => {
  return (
    <Column className={'searchContainer'}>
      <GridBackground />
      <PrivacyPolicyContent />
      <Footer /> 
    </Column>
  );
};
