import React from 'react';

const GridBackground: React.FC = () => {

    return (
        <div className="tw-fixed tw-inset-0 -tw-z-20 tw-min-h-screen tw-w-full tw-bg-white tw-bg-[linear-gradient(to_right,#00000006_2px,transparent_2px),linear-gradient(to_bottom,#0000000D_2px,transparent_2px)] tw-bg-[size:32px_32px]">
        <div className="tw-w-full tw-h-1/4 tw-fixed tw-bg-gradient-to-b tw-from-[rgba(226,230,253,0.8)] tw-to-[rgba(255,255,255,0.1)]"></div>
    </div>
    );
};

export default GridBackground;