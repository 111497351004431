import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { fabutheme } from "../MaterialUI/muicolortheme";
import { Grid } from '@mui/material';

const steps = [
  {
    label: 'T-Gate GaN HEMT',
    description: `This is a T-gate AlGaN/GaN high electron mobility transistor (HEMT) used for high frequency amplifiers and power electronics. The process editor allows for lift-off processes for gate defintion and conformal coatings used for passivation.`,
    image: '/T-gate-example.png',
    link: '/process-editor/8389e9ba-3c84-4d6b-a1ec-fae18d5e963f'

  },
  {
    label: 'Basic Inverter',
    description:
      'Simple process flow to develope a CMOS inverter.' +
      '\n\n\n\n\n The most fundamental building block of digital logic circuits',
    image: '/StripResistExample.png',
    link: '/process-editor/bb4b2aab-2298-4985-9e52-ae7582ef0ae1'

  }
  //   {
  //     label: 'Capacitive Cantilever',
  //     description:
  //       'Simple process flow to develope a CMOS inverter.' +
  //       '\n\n\n\n\n The most fundamental building block of digital logic circuits',
  //     image: '/Wet-Etch.png',
  //     link: 'https://www.fabublox.com/process-editor/bb4b2aab-2298-4985-9e52-ae7582ef0ae1'

  //   }



  // {
  //   label: 'SemiFab',
  //   description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
  //   tempor incididunt ut labore et dolore magna aliqua. Metus vulputate eu scelerisque felis 
  //   imperdiet. Gravida arcu ac tortor dignissim convallis aenean. Mi sit amet mauris commodo.`,
  //   image: '/Jan_headshot.png',
  // }
];

export default function ProcessCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div id='carousel' className="tw-flex tw-justify-center tw-z-0 tw-px-4 tw-py-36 tw-bg-gradient-to-t tw-from-[#181818] tw-to-white">
          <Box className="tw-relative" sx={{ maxWidth: 1000, flexGrow: 1 }}>
            <div className='tw-rounded-xl tw-bg-[white] tw-outline tw-outline- tw-outline-[#DEE2FB] tw-shadow-2xl hover:tw-drop-shadow-2xl hover:tw-outline-4'>
              <div>

              </div>
              <div>
                <div className='tw-pl-8 tw-pt-8 -tw-mb-2 tw-text-3xl tw-font-bold'>
                  {steps[activeStep].label}
                </div>
                <div className='tw-pl-8 tw-mt-2 -tw-mb-2 tw-text-lg tw-font-regular tw-italic tw-text-slate-500'>
                  Designed in FabuBlox
                </div>
                <Box sx={{ display: 'flex', height: { xs: 'auto', md: '250px' }, width: '100%', pl: 4, pt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ flex: 1 }}>
                        {steps[activeStep].description}

                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='tw-mb-16 tw-mx-8'>
                          <img
                            src={steps[activeStep].image}
                            alt={steps[activeStep].label}
                            style={{ maxHeight: '80%', maxWidth: '80%', objectFit: 'contain' }}
                            className=''
                          />
                        </div>
                      </Box>
                    </Grid>

                  </Grid>
                </Box>

              </div>
              <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{ background: '#DEE2FB', borderRadius: '0px 0px 10px 10px' }}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </Button>
                }
              />
            </div>
            <div>
              <Button variant="outlined" size="medium" href={steps[activeStep].link} className="tw-bg-white tw-absolute tw-bottom-16 tw-left-8">
                View full process
              </Button>
            </div>
          </Box>

        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
