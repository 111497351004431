import React from 'react';
import { Section1TextOnly } from './Section1TextOnly';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const FabuBaseSection: React.FC = () => {
  return (
    <div>
        <Section1TextOnly
          background="#181818"
          header={
            <div className="tw-text-7xl tw-flex tw-justify-center sm:tw-text-7xl md:tw-text-8xl  tw-font-black tw-drop-shadow-[] tw-animation-delay-200">
            <h2>Fab</h2>
            <h2 className="tw-text-transparent tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299] tw-to-[#ff9567] tw-bg-clip-text">
              u
            </h2>
            <h2>Base</h2>
          </div>
          }
          description={
          <h2>
            Our peer-created process database&nbsp;
            <span className="tw-font-bold tw-italic tw-inline-block tw-relative tw-mt">
              accelerating
            </span>
            &nbsp;your innovations
          </h2>
          }
          heading1="Discover Hundreds of Processes"
          details1="Explore our repository of public processes created by other users. Get inspired through similar projects, or speed up your work by copying modules to avoid reinventing a process step. With publications in the FabuBase, you'll never have to guess what steps were omitted from a paper that prevent you from reproducing their results."
          heading2="Control Your Contributions"
          details2="All your process flows are private by default, but your publications can make a greater impact by sharing them with the world. Creating a public process automatically adds it to the FabuBase where others can explore your work. Add a reference to the process for any publications and cite the process in your papers to increase exposure."
          buttonText="Search the FABUBASE"
          buttonRedirect="https://www.fabublox.com/fabubase"
          textColor='white'
          lineColor='white'
        />

    </div>
  );
};

export default FabuBaseSection;
