import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Navbar, Alignment } from '@blueprintjs/core'
import LoginLogoutButton from './components/login-logout-button'
import { ProcessEditorPage } from './pages/ProcessEditorPage'
import { ProtectedRoute } from './auth/ProtectedRoute'
import { MyProcesses } from './pages/MyProcesses'
import { SearchPage } from './pages/SearchPage'
import { LandingPage } from './pages/LandingPage'
import { PageHeader } from './components/PageHeader'
import { Column, Row } from './Layout/layouts'
import { GroupProcesses } from './pages/GroupProcesses'
import { MyBlox } from './pages/MyBlox'
import { ProcessViewerPage } from './pages/ProcessViewerPage'
import { JoinGroupPage } from './components/JoinGroupPage'
import { ProcessSettingsProvider } from './hooks/state/process-settings-provider'
import { SVGTestPage } from './pages/SVGTestPage'
import { ModuleEditorPage } from './pages/ModuleEditorPage'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { TermsOfService } from './pages/TermsOfServicePage'

export const FabuRoutes = () => {

  const [inputValue, setInputValue] = useState<string>('')

  const history = useHistory()
 
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      
      const currentPath = history.location.pathname;
      const newPath = `/fabubase/?keyword=${inputValue}`;
      
      if (currentPath === '/fabubase/') {
        // Reload the current page if the new path is the same as the current path
        window.location.href = newPath;

      } else {
        // Navigate to the new path if it is different from the current path
        history.push(newPath);
      }
    }
  }
  

  return (
    <>
      <Navbar>
        <Row>
          <Column style={{marginRight: 'auto', height: '50px'}}><PageHeader/></Column>
          <Column style={{margin: 'auto', height: '50px'}} className='search-box'>
            <input
              type='text'
              className='searchbox'
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleSearch}
              placeholder='Search the FabuBase...'
            />
          </Column>
          <Column style={{marginLeft: 'auto', height: '50px'}}>
            <Row style={{margin: 'auto'}}>
              <LoginLogoutButton />
            </Row>
          </Column>
        </Row>
        <Navbar.Group align={Alignment.LEFT}>
        </Navbar.Group>
        {/* <Navbar.Group align={Alignment.CENTER}>
          <button className='btn-fabknow' onClick={handleFavKnowClick}>
            FabuKnow
          </button>

          
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <LoginLogoutButton />
        </Navbar.Group> */}
      </Navbar>
      <Switch>
        <Route exact={true} path={'/process-viewer/:processId?'}>
          <ProcessSettingsProvider key='viewer'>
            <ProcessViewerPage />
          </ProcessSettingsProvider>
        </Route>
        <Route exact={true} path='/'>
          <LandingPage />
        </Route>
        <Route exact={true} path='/privacy-policy'>
          <PrivacyPolicy />
        </Route>
        <Route exact={true} path='/terms-of-service'>
          <TermsOfService />
        </Route>
          <ProtectedRoute exact={true} path='/process-editor/:processId?'>
            <ProcessSettingsProvider key='process-editor'>
              <ProcessEditorPage />
            </ProcessSettingsProvider>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path='/module-editor/:processId?'>
            <ProcessSettingsProvider isModule={true} key='module-editor'>
              <ModuleEditorPage />
            </ProcessSettingsProvider>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/my-blox'}>
            <MyBlox />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/my-processes'}>
            <MyProcesses key='my-processes' />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/my-group-processes'}>
            <GroupProcesses key='group-processes' />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/my-modules'}>
            <MyProcesses key='my-modules' isModule={true} />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/my-group-modules'}>
            <GroupProcesses key='group-modules' isModule={true}/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={'/fabubase'}>
            <SearchPage />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path='/join-group'>
            <JoinGroupPage />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path='/svg-test'>
            <SVGTestPage />
          </ProtectedRoute>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </>
  )
}
