import { Color } from "../utils/Color";
import { patternToBitarray, subtractPatterns } from "./PatternOps";
import { DopeCommand, DrawCommand, DrawingFace } from "./SVGEngine";


export class SVGDrawService {

    // perspective angle
    alpha: number;

    threeDim: boolean;

    thumbnailMode: boolean;
    exportPreviewMode: boolean;

    epsilon: number;

    constructor(threeDim: boolean) {
        this.alpha = 45 * Math.PI / 180; // projection angle: 45 degrees
        this.threeDim = threeDim;
        this.thumbnailMode = false;
        this.epsilon = 0.2;
        this.exportPreviewMode = false;
    }

    setThreeDim(threeDim: boolean) {
        this.threeDim = threeDim;
    }

    setThumbnailMode(thumbnailMode: boolean) {
        this.thumbnailMode = thumbnailMode;
    }

    setExportPreviewMode(exportPreviewMode: boolean) {
        this.exportPreviewMode = exportPreviewMode;
    }

    setEpsilon(epsilon: number) {
        this.epsilon = epsilon;
    }

    // draw the front face polygon
    // A: width/height of the square (base of the box), from an orthogonal top view
    // h: the height of the box
    // x: horizontal offset, from an orthogonal top view
    // y: vertical offset, from an orthogonal top view
    // z: offset of the box (from which height do we start drawing), from a perspective side view
    drawBoxFront(A: number, B: number, h: number, x:number, y: number, z:number): number[] {
        const pointsFront: number[] = [];

        // FRONT
        // bottom left corner
        pointsFront.push(x + 0.5 * y * Math.cos(this.alpha));
        pointsFront.push(z - 0.5 * y * Math.sin(this.alpha));

        // top left corner
        pointsFront.push(x + 0.5 * y * Math.cos(this.alpha));
        pointsFront.push(z  - 0.5 * y * Math.sin(this.alpha) - h);

        // top right corner
        pointsFront.push(x + 0.5 * y * Math.cos(this.alpha) + A);
        pointsFront.push(z - 0.5 * y * Math.sin(this.alpha)- h);

        // bottom right corner
        pointsFront.push(x + 0.5 * y * Math.cos(this.alpha)+ A);
        pointsFront.push(z - 0.5 * y * Math.sin(this.alpha));

        return pointsFront;
    }

    // top face polygon
    drawBoxTop(A: number, B: number, h: number, x:number, y: number, z:number): number[] {
        const pointsTop: number[] = [];

        // TOP
        // bottom left corner
        pointsTop.push(x + 0.5 * y * Math.cos(this.alpha));
        pointsTop.push(z - 0.5 * y * Math.sin(this.alpha) - h);

        // top left corner
        pointsTop.push(x + 0.5 * B * Math.cos(this.alpha) + 0.5 * y * Math.cos(this.alpha));
        pointsTop.push(z - 0.5 * y * Math.sin(this.alpha) - h - 0.5 * B * Math.cos(this.alpha));

        // top right corner
        pointsTop.push(x + A + 0.5 * B * Math.cos(this.alpha) + 0.5 * y * Math.cos(this.alpha));
        pointsTop.push(z - 0.5 * y * Math.sin(this.alpha) - h - 0.5 * B * Math.cos(this.alpha));

        // bottom right corner
        pointsTop.push(x + 0.5 * y * Math.cos(this.alpha) + A);
        pointsTop.push(z - 0.5 * y * Math.sin(this.alpha) - h);

        return pointsTop;
    }

    // side face polygon
    drawBoxSide(A: number, B: number, h: number, x:number, y: number, z:number): number[] {
        const pointsSide: number[] = [];
        // SIDE
        // bottom left corner
        pointsSide.push(x  + 0.5 * y * Math.cos(this.alpha) + A);
        pointsSide.push(z- 0.5 * y * Math.sin(this.alpha));

        // top left corner
        pointsSide.push(x  + 0.5 * y * Math.cos(this.alpha) + A);
        pointsSide.push(z - 0.5 * y * Math.sin(this.alpha) - h);

        // top right corner
        pointsSide.push(x  + 0.5 * y * Math.cos(this.alpha) + A + 0.5 * B * Math.cos(this.alpha));
        pointsSide.push(z- 0.5 * y * Math.sin(this.alpha) - h - 0.5 * B * Math.cos(this.alpha));

        // bottom right corner
        pointsSide.push(x  + 0.5 * y * Math.cos(this.alpha) + A + 0.5 * B * Math.cos(this.alpha));
        pointsSide.push(z - 0.5 * y * Math.sin(this.alpha)- 0.5 * B * Math.cos(this.alpha));

        return pointsSide;
    }

    convertPointsToString(points: number[]): string {
        let str = "";

        for (let i = 0; i < points.length; i++) {
            str += points[i];
            str += (i % 2 === 0) ? "," : " ";
        }

        if (str.length > 0) {
            str = str.substring(0, str.length - 1);
        }

        return str;
    }

    convertPointsToStringAndAddRoundedCornersBottom(points: number[], radius:number): string {
        let str = "M";

        // start with top left
        str += points[2] + "," + points[3] + " ";

        // go to the bottom left, minus the corner on the y
        str += points[0] + "," + (points[1] - radius) + " ";

        // add an quadratic curve
        str += "Q" + points[0] + "," + points[1] + " " + (points[0] + radius) + "," + points[1] + " ";

        // go to the bottom left, without the corner on the x
        str += (points[0] + radius) + "," + points[1] + " ";

        // go to the bottom right, without the corner on the x
        str += (points[6] - radius) + "," + points[7] + " ";

        str += "Q" + points[6] + "," + points[7] + " " + points[6] + "," + (points[7]-radius) + " ";

        // go to the bottom right, without the corner on the y
        str += points[6] + "," + (points[7]-radius) + " ";

        // go to top right
        str += points[4] + "," + points[5] + "z";

        return str;
    }

    convertPointsToStringAndAddRoundedCornersTop(points: number[], radius:number): string {
        let str = "M";

        // start with bottom left
        str += points[0] + "," + points[1] + " ";

        // go to the top left, minus the corner on the y
        str += points[2] + "," + (points[3] + radius) + " ";

        // add an quadratic curve
        str += "Q" + points[2] + "," + points[3] + " " + (points[2] + radius) + "," + points[3] + " ";

        // go to the top left, without the corner on the x
        str += (points[2] + radius) + "," + points[3] + " ";

        // go to the top right, without the corner on the x
        str += (points[4] - radius) + "," + points[5] + " ";

        str += "Q" + points[4] + "," + points[5] + " " + points[4] + "," + (points[5]+radius) + " ";

        // go to the top right, without the corner on the y
        str += points[4] + "," + (points[5]+radius) + " ";

        // go to bottom right
        str += points[6] + "," + points[7] + "z";

        return str;
    }

    generateKey(A: number, B: number, height: number, x: number, y: number, z: number): string {
        return A + "-" + B + "-" + x + "-" + y + "-" +z + "-" + height;
    }

    // adds a box
    // A: 1st dimension of the base
    // B: 2nd dimension of the base
    // height: height of the box
    // x: x offset
    // y: y offset
    // z: z offset
    // color
    getBox(A: number, B: number, height: number, x: number, y: number, z: number, color: Color, face: DrawingFace): JSX.Element {
        const key = this.generateKey(A,B,height,x,y,z);
        if (face === DrawingFace.Side) {
            const pointsSide: number[] = this.threeDim ? this.drawBoxSide(A, B, height, x, y, z) : [];

            const sideStyle = {
                fill: color.getSideRGB(),
                fillOpacity: color.getA(),
                //stroke: "#999",
            }

            return <polygon key={"poly-" + key + "-side"}  style={sideStyle} points={this.convertPointsToString(pointsSide)} />;
        } else if (face === DrawingFace.Front) {
            const fillColor = this.threeDim ? color.getFrontRGB() : color.getRGB();
            if ((this.exportPreviewMode || this.thumbnailMode) && fillColor.startsWith("rgb(255,255,255")) {
                // add small gray frame
                const pointsFront: number[] = this.drawBoxFront(A+this.epsilon/2 - 0.5, B, height+this.epsilon, x-this.epsilon/2 + 0.25, y, z);
    
                const frontStyle = {
                    fill: fillColor,
                    fillOpacity: color.getA(),
                    stroke: "#999",
                    strokeWidth: 0.5
                }
    
                return <polygon key={"poly-" + key + "-front"} style={frontStyle} points={this.convertPointsToString(pointsFront)}/>
            } else {
                const pointsFront: number[] = this.drawBoxFront(A+this.epsilon/2, B, height+this.epsilon, x-this.epsilon/2, y, z);
    
                const frontStyle = {
                    fill: fillColor,
                    fillOpacity: color.getA(),
                    //stroke: "#900",
                    //strokeWidth: 0.5
                }
    
                return <polygon key={"poly-" + key + "-front"} style={frontStyle} points={this.convertPointsToString(pointsFront)}/>
            }
            
        } else {
            const pointsTop: number[] = this.threeDim ? this.drawBoxTop(A, B, height, x, y, z) : [];

            const topStyle = {
                fill: color.getRGB(),
                fillOpacity: color.getA(),
                //stroke: "#999",
            }

            return <polygon key={"poly-" + key + "-top"}  style={topStyle} points={this.convertPointsToString(pointsTop)}/>;
        }
    }

    getSquareBox(width: number, height: number, x: number, y: number, z: number, color: Color, face: DrawingFace): JSX.Element {
        return this.getBox(width, width, height, x, y, z, color, face);
    }

    // adds a semi box
    // A: 1st dimension of the base
    // B: 2nd dimension of the base
    // depth: depth of the box
    // x: x offset
    // y: y offset
    // topZ: z offset
    // color
    getRoundedBottomBox(A: number, B: number, depth: number, x: number, y: number, topZ: number, color: Color, radius: number, face: DrawingFace): JSX.Element {
        return this.getRoundedBox('bottom', A, B, depth, x, y, topZ, color, radius, face);

    }

    getRoundedTopBox(A: number, B: number, depth: number, x: number, y: number, topZ: number, color: Color, radius: number, face: DrawingFace): JSX.Element {
        return this.getRoundedBox('top', A, B, depth, x, y, topZ, color, radius, face);
    }

    getRoundedBox(topOrBottom: string, A: number, B: number, depth: number, x: number, y: number, topZ: number, color: Color, radius: number, face: DrawingFace): JSX.Element {
        const key = this.generateKey(A,B,depth,x,y,topZ);
        if (face === DrawingFace.Side) {
            // TODO
            return <></>
        } else if (face === DrawingFace.Front) {
            const pointsFront: number[] = this.drawBoxFront(A+this.epsilon/2, B, depth+this.epsilon, x-this.epsilon/2, y, topZ+depth);

            const frontStyle = {
                fill: (this.threeDim || this.thumbnailMode) ? color.getFrontRGB() : color.getRGB(),
                fillOpacity: color.getA(),
                //stroke: "#999",
            }

            if (topOrBottom === 'top') {
                return <path key={"poly-" + key + "-front"} style={frontStyle} d={this.convertPointsToStringAndAddRoundedCornersTop(pointsFront, radius)}/>
            } else {
                return <path key={"poly-" + key + "-front"} style={frontStyle} d={this.convertPointsToStringAndAddRoundedCornersBottom(pointsFront, radius)}/>
            }
        } else {
            // TODO
            return <></>
        }
    }

    getDrawing(dc: DrawCommand, face: DrawingFace) : JSX.Element {
        switch (dc.type) {
            case "PatternPreDevelop": {
                const polygons : JSX.Element[] = [];
                const originalPattern : number[] = dc.pattern ?? [];
                const exposedPattern : number[] = dc.exposedPattern ?? [];
                const nonexposedPattern = subtractPatterns(originalPattern, exposedPattern);

                const patterns = [exposedPattern, nonexposedPattern];
                for (let patternIdx = 0; patternIdx < 2; patternIdx++) {
                    const pattern = patterns[patternIdx];
                    const patternExposed = patternIdx === 0;
                    let total  = 0;
                    let lastBox  = 0;
                    for (let i = 0; i < pattern.length; i++) {
                        total += pattern[i];
                        if (pattern[i] > 0)
                            lastBox = i;
                    }
                    if (total > 0) {
                        let exposed : boolean = patternExposed;
                        let x = 0;
                        for (let i = 0; i < pattern.length; i++) {
                            exposed = !exposed;

                            if (pattern[i] === 0)
                                continue; 
    
                            const w = pattern[i] * dc.width / total;
                            if (!patternExposed !== exposed) {
                                if (face !== DrawingFace.Side || lastBox === i) {
                                    polygons.push(this.getBox(w, dc.width, dc.height, x, 0, dc.z, exposed ? dc.color.getDarker() : dc.color, face));
                                }
                            }
    
                            x += w;
                        }
                    }
                }
                return (
                    <g key={dc.uniqueKey}>
                      {polygons}
                    </g>);
            }
            case "Pattern":
                {
                    const polygons : JSX.Element[] = [];
                    let total  = 0;
                    const pattern : number[] = dc.pattern ?? [];
                    for (let i = 0; i < pattern.length; i++) {
                        total += pattern[i];
                    }
                    if (total > 0) {
                        let exposed  = false;
                        let x = 0;
                        for (let i = 0; i < pattern.length; i++) {
                            exposed = !exposed;

                            if (pattern[i] === 0)
                                continue; 

                            const w = pattern[i] * dc.width / total;
                            if (!exposed) {
                                polygons.push(this.getBox(w, dc.width, dc.height, x, 0, dc.z, dc.color, face));
                            }

                            x += w;
                        }

                        if (dc.dopeCommands) {
                            const duplicates: { [key: string]: boolean } = {};

                            let hasTopPattern = false;
                            let hasSidePattern = false;
                            for (let j = 0; j < dc.dopeCommands.length; j++) {
                                const dpc: DopeCommand = dc.dopeCommands[j];

                                if (dpc.thickness === 0) {
                                    continue;
                                }

                                if (!dpc.sideDope) {
                                    hasTopPattern = true;
                                } else {
                                    hasSidePattern = true;
                                }
                            }

                            for (let j = 0; j < dc.dopeCommands.length; j++) {
                                const dpc: DopeCommand = dc.dopeCommands[j];

                                if (dpc.thickness === 0) {
                                    continue;
                                }

                                // TODO: prevent code duplication and move this code to a separate function
                                let total  = 0;
                                
                                const dopePattern : number[] = dpc.pattern ?? [];
                                for (let i = 0; i < dopePattern.length; i++) {
                                    total += dopePattern[i];
                                }
                                if (total > 0) {
                                    let exposed  = false;
                                    let x  = 0;
                                    for (let i = 0; i < dopePattern.length; i++) {
                                        exposed = !exposed;

                                        if (dopePattern[i] === 0)
                                            continue; 

                                        const w = dopePattern[i] * dc.width / total;
                                        if (!exposed) {
                                            const key = dpc.depth + "-" + x + "-" + w;
                                            if (!duplicates[key]) {
                                                if (dpc.flipped) {
                                                    if (dpc.sideDope && hasTopPattern) {
                                                        polygons.push(this.getRoundedTopBox(w, dc.width, dpc.depth, x, 0, dc.z-dpc.depth-w, dpc.color, dpc.cornerRadius, face));
                                                    } else if (!dpc.sideDope && hasSidePattern) {
                                                        polygons.push(this.getRoundedTopBox(w, dc.width, dpc.depth, x, 0, dc.z-dpc.depth, dpc.color, 0, face));
                                                    } else { 
                                                        polygons.push(this.getRoundedTopBox(w, dc.width, dpc.depth, x, 0, dc.z-dpc.depth, dpc.color, dpc.cornerRadius, face));
                                                    }
                                                } else {
                                                    if (dpc.sideDope && hasTopPattern) {
                                                        if (dpc.depth > w) {
                                                            polygons.push(this.getRoundedBottomBox(w, dc.width, dpc.depth-w, x, 0, dc.z-dc.height+w, dpc.color, dpc.cornerRadius, face));
                                                        } else {
                                                            polygons.push(this.getRoundedBottomBox(w, dc.width, dpc.depth-(w/2), x, 0, dc.z-dc.height+(w/2), dpc.color, dpc.cornerRadius, face));
                                                        }
                                                    } else if (!dpc.sideDope && hasSidePattern) {
                                                        polygons.push(this.getRoundedBottomBox(w, dc.width, dpc.depth, x, 0, dc.z-dc.height, dpc.color, 0, face));
                                                    } else {
                                                        polygons.push(this.getRoundedBottomBox(w, dc.width, dpc.depth, x, 0, dc.z-dc.height, dpc.color, dpc.cornerRadius, face));
                                                    }
                                                }
                                                duplicates[key] = true;
                                            }
                                            
                                        }

                                        x += w;
                                    }
                                }
                            }
                        }
                    }
                    return (
                        <g key={dc.uniqueKey}>
                            {polygons}
                        </g>);
                }
            default:
                return <></>;
        }
    }

    getLabel(dc: DrawCommand) : JSX.Element {
        const key: string = "label-" + dc.uniqueKey;

        // TODO: max height
        const pattern = dc.pattern ?? [0, 1];
        const bit = patternToBitarray(pattern, 1);
        if (bit.length === 1 && bit[0] === true) {
            let marginTop = 0;
            if (dc.dopeCommands) {
                for (let i = 0; i < dc.dopeCommands.length; i++) {
                    marginTop = Math.max(marginTop, dc.dopeCommands[i].depth)
                }
            }
            let height = dc.height;
            if (marginTop > 0 && marginTop >= Math.max(0, (height-9))) marginTop = height - 10;
            height -= marginTop;
            const fontSize = Math.min(9, dc.height);
            const y = dc.z - (height/2) + 0.5;
            return (<text key={key} x={dc.width/2} y={y} fontSize={fontSize} dominantBaseline="middle" fontFamily="sans-serif" textAnchor="middle">{dc.label ?? ""}</text>);
        } 
        return <></>;
    }
}
