import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Button, Container } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { fabutheme } from '../MaterialUI/muicolortheme';

interface SectionTextOnlyProps {
  background: string;
  header: React.ReactNode | string;
  description: React.ReactNode | string;
  heading1: React.ReactNode | string;
  details1: React.ReactNode | string;
  heading2: React.ReactNode | string;
  details2: React.ReactNode | string;
  buttonText: string;
  buttonRedirect: string;
  textColor?: string;
  lineColor?: string;  // New optional prop for line background color
}

export const Section1TextOnly: React.FC<SectionTextOnlyProps> = ({
  background,
  header,
  description,
  heading1,
  details1,
  heading2,
  details2,
  buttonText,
  buttonRedirect,
  textColor,
  lineColor = 'black',  // Default color is black
}) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const detailsRef1 = useRef<HTMLDivElement>(null);
  const detailsRef2 = useRef<HTMLDivElement>(null);
  const buttonDivRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.01,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('tw-animate-fadeIn');
        }
      });
    }, options);

    if (sectionRef.current) observer.observe(sectionRef.current);
    if (descRef.current) observer.observe(descRef.current);
    if (detailsRef1.current) observer.observe(detailsRef1.current);
    if (detailsRef2.current) observer.observe(detailsRef2.current);
    if (buttonDivRef.current) observer.observe(buttonDivRef.current);
    if (lineRef.current) observer.observe(lineRef.current);

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
      if (descRef.current) observer.unobserve(descRef.current);
      if (detailsRef1.current) observer.unobserve(detailsRef1.current);
      if (detailsRef2.current) observer.unobserve(detailsRef2.current);
      if (buttonDivRef.current) observer.unobserve(buttonDivRef.current);
      if (lineRef.current) observer.unobserve(lineRef.current);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div className='tw-min-h-94svh' style={{ backgroundColor: background }}>
        <Container maxWidth="xl"> 
          <div className="tw-flex tw-justify-center">
            <div className="tw-p-1 md:tw-p-4 tw-w-full">
              <div className="tw-flex tw-flex-col tw-items-left tw-justify-center tw-mt-4 tw-mx-4">
                <div className="tw-mt-8">
                  <div ref={sectionRef} className={`tw-text-5xl md:tw-text-6xl lg:tw-text-7xl tw-flex tw-justify-start tw-font-black opacity-0`} style={{ color: textColor }}>
                    <h5>{header}</h5>
                  </div>
                  <div ref={descRef} className={`tw-text-2xl tw-flex tw-mt-4 tw-mb-4 tw-justify-start opacity-0 tw-animation-delay-200`} style={{ color: textColor }}>
                    <h2 className="tw-text-left">
                      {description}&nbsp;
                    </h2>
                  </div>
                </div>
              </div>
              <div ref={lineRef} className='tw-w-1/4 tw-justify-start tw-h-0.5 tw-mx-4 tw-mb-4 -tw-mt-8' style={{ backgroundColor: lineColor }}></div>

              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6 tw-justify-center">
                <div ref={detailsRef1} className="tw-rounded-xl tw-overflow-hidden tw-top-0 tw-flex tw-flex-col tw-p-4 opacity-0 tw-animation-delay-400">
                  <h5 className="tw-text-left tw-text-3xl tw-font-black" style={{ color: textColor }}>{heading1}</h5>
                  <h2 className='tw-text-left tw-text-xl tw-mt-4' style={{ color: textColor }}>{details1}</h2>
                </div>
                <div ref={detailsRef2} className="tw-rounded-xl tw-overflow-hidden tw-top-0 tw-flex tw-flex-col tw-p-4 opacity-0 tw-animation-delay-600">
                  <h5 className="tw-text-left tw-text-3xl tw-font-black" style={{ color: textColor }}>{heading2}</h5>
                  <h2 className='tw-text-left tw-text-xl tw-mt-4' style={{ color: textColor }}>{details2}</h2>
                </div>
              </div>
              <div ref={buttonDivRef} className='tw-flex tw-justify-start tw-mx-4 tw-bottom-10 opacity-0 tw-animation-delay-800'>
                <a href={buttonRedirect} target='_blank'>
                  <Button variant="contained" color='primary' size="large" className="tw-my-8">
                    {buttonText}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          </Container>
        </div>    
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
