import { Row } from "../Layout/layouts";
import { Icon, Dialog, Classes } from "@blueprintjs/core";
import { Divide, IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";

export interface StackProps {
    bloxSVG: JSX.Element,
    renderMenu: () => JSX.Element,
    bloxName?: string,

}

export const Stack: React.FC<StackProps> = (props) => {
    const { bloxName, bloxSVG, renderMenu } = props;

    const [isOpen, setIsOpen] = useState(false); // State to manage dialog visibility

    const toggleDialog = () => setIsOpen(!isOpen); // Handler to toggle dialog

    return (
        <Row>
            <div className="bloxBottom" >
                <div onClick={toggleDialog} style={{width:'120px', position:'absolute', bottom:'10px', left:'15px', cursor:'zoom-in'}}>
                    {bloxSVG}
                </div>
                <div className="bloxMenuIcon">
                    <Popover2 content={renderMenu()}>
                        <Icon size={20} icon={IconNames.MORE} />
                    </Popover2>
                </div>
            </div>
            <Dialog
                isOpen={isOpen}
                onClose={toggleDialog}
                title={bloxName ?? "Zoom View"} 
                style={{width:'600px', height:'auto', backgroundColor:'#e2e6fd'}}
            >
                <div style={{width:'500px', margin:'auto', padding:'20px'}}>
                    {bloxSVG}
                </div>
            </Dialog>
        </Row>
    );
}


