import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import { WelcomeBox } from '../components/WelcomeBox';
import { TeamListing } from '../components/TeamListing';
import { Column } from '../Layout/layouts';
import { ProcessEditorInfo } from '../components/ProcessEditorInfo';
import { Footer } from '../components/Footer';

import FeaturesCarousel from '../components/Carousel';
import GridBackground from '../components/GridBackground';
import FabuBaseSection from '../components/FabuBaseInfo';
import { FabuKnowInfo } from '../components/FabuKnowInfo';
import ProcessCarousel from '../components/ProcessCarousel';
import { UnderConstruction } from '../components/UnderConstruction';



export const LandingPage: React.FC = () => {
  return (
    <Column className={'searchContainer'}>
      <GridBackground />
        <WelcomeBox />
        <FabuBaseSection /> 
        <ProcessEditorInfo />
        <ProcessCarousel />
        {/* <FabuKnowInfo /> */}
        {/* <UnderConstruction /> */}
        <TeamListing />
        
      <Footer /> 
    </Column>
  );
};
