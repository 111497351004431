import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface DopeDiffusion extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    materialDisabled: () => boolean | null;
    diffusionTime: number | null;
    diffusionTimeUnit: Units | null; 
    temperature: number | null;
    temperatureUnit: Units | null;

    // DISPLAY
    environmentalDiffusion: boolean | null;
    layerColor: IColor | null;
    layerThickness: number | null;

    // SEMIFAB
    diffusionDistance: number | null;
    diffusionDistanceUnit: Units | null; 
    // dopantLabelsToDiffuse: string[] | null;
    layerLabelsToDiffuseIn: string[] | null;
}

export const DopeDiffusionDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Ion Species",
        placeholder: "Enter dopant species",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    diffusionTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Time",
        placeholder: "Enter diffusion time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    temperature: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Temperature",
        placeholder: "Enter temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    environmentalDiffusion: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Environmental",
        order: 0,
        tabs: [Tab.EXPERIMENTAL, Tab.DISPLAY,Tab.SEMIFAB],
        defaultValue: false,
        infoContent: "Use this to enable dopant diffusion from the environment such as in a diffusion furnace."
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Diffusion Distance",
        order: 21,
        tabs: [Tab.DISPLAY],
    },

    // SEMIFAB
    diffusionDistance: {
        fieldType: DisplayFieldTypes.Input,
        label: "Diffusion Distance",
        placeholder: "Enter diffusion distance",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 1,
        tabs: [Tab.SEMIFAB],
    },
    // dopantLabelsToDiffuse: {
    //     fieldType: DisplayFieldTypes.MultiMaterialSelect,
    //     label: "Dopant(s) to Diffuse",
    //     order: 19,
    //     tabs: [Tab.SEMIFAB],
    // },
    layerLabelsToDiffuseIn: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Diffuse In",
        order: 19,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
}

export const getDopeDiffusion = ({stepNumber}: BloxArgs): DopeDiffusion => ({
        name: `Dopant Diffusion ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.DopeDiffusion,
        material: null,
        materialDisabled: function () {
            return !this.environmentalDiffusion;
        },
        diffusionTime: null,
        diffusionTimeUnit: Units.MIN,
        diffusionDistance: null,
        diffusionDistanceUnit: Units.NM,
        temperature: null,
        temperatureUnit: Units.CELSIUS,
        environmentalDiffusion: false,
        layerColor: {R: 0, G: 0, B: 0, A: 0.25},
        layerThickness: 4,
        commentField: null,
        customFields: {},
        // dopantLabelsToDiffuse: [],
        layerLabelsToDiffuseIn: [],
});
