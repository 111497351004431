import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { DisplayFieldTypes, Tab, Units } from "../enums";

export interface PlasmaAsh extends BaseBlox {
    // to do user select number of cleaning steps
    gas: string | null;
    etchRate: number | null;
    etchRateUnit: Units | null;
    etchTime: number | null;
    etchTimeUnit: Units | null;
    rfPower: number | null;
    rfPowerUnit: Units | null;
    pressure: number | null;
    pressureUnit: Units | null;
    etchDepth: number | null;
    etchDepthUnit: Units | null;
    etchDepthDisabled: () => boolean | null;

    layerLabelsToEtch: string[] | null;
    percentToEtch: number | null;
    isotropicEtch: boolean | null;
    sidewaysEtch: number | null;
    keepFloatingStructures: boolean | null;
    etchBuriedLayers: boolean | null;
    // SEMIFAB
    isIsotropic: boolean | null;
    isIsotropicDisabled: () => boolean | null;
    isFullRemoval: boolean | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
    sidewallAngleDisabled: () => boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const plasmaAshDisplay: DisplayMap = {
    layerLabelsToEtch: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Etch",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    percentToEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Partial Etch",
        order: 1,
        tabs: [Tab.DISPLAY],
        defaultValue: 100,
        stepSize: 2
    },
    sidewaysEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sideways Etch",
        order: 2,
        tabs: [Tab.DISPLAY],
        defaultValue: 0,
        stepSize: 2
    },
    isotropicEtch: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Isotropic Etch",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: false
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    etchBuriedLayers: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Etch Buried Layers",
        order: 5,
        tabs: [Tab.DISPLAY],
        defaultValue: false
    },
    
    gas: {
        fieldType: DisplayFieldTypes.Input,
        label: "Gas composition",
        placeholder: "Enter gas names and ratios",
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
      },
    rfPower: {
        fieldType: DisplayFieldTypes.Input,
        label: "RF Power",
        placeholder: "Enter RF power",
        isNumber: true,
        order: 7,
        units: [Units.WATT],
        tabs: [Tab.EXPERIMENTAL],
    },
    pressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time",
        placeholder: "Enter ashing time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Rate",
        placeholder: "Enter etch rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.NMPERMIN,Units.UMPERMIN,Units.UMPERHOUR],
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Depth",
        placeholder: "Enter etch depth",
        isNumber: true,
        order: 11,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    // SEMIFAB
    isFullRemoval: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Complete Material Removal",
        order: 3,
        tabs: [Tab.SEMIFAB],
        defaultValue: false,
        infoContent: "This will fully remove the selected layers even if they are buried."
    },
    isIsotropic: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Isotropic Etch",
        order: 3,
        tabs: [Tab.SEMIFAB],
        defaultValue: false
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 12,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 9,
        tabs: [Tab.SEMIFAB],
        isOptionalSemifab: true,
    },

}

export const getPlasmaAsh = ({stepNumber}: BloxArgs): PlasmaAsh => ({
        name: `Plasma Ashing ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.PlasmaAsh,
        gas: null,
        rfPower: null,
        rfPowerUnit: Units.WATT,
        etchRate: null,
        etchRateUnit: Units.NMPERSEC,
        etchTime: null,
        etchTimeUnit: Units.SEC,
        etchDepth: null,
        etchDepthUnit: Units.NM,
        etchDepthDisabled: function () {
            return this.isFullRemoval ?? false;
        },
        pressure: null,
        pressureUnit: Units.TORR,
        commentField: null,
        layerLabelsToEtch: [],
        percentToEtch: 100,
        isotropicEtch: false,
        sidewaysEtch: 0,
        keepFloatingStructures: false,
        etchBuriedLayers: false,
        customFields: {},
        // SEMIFAB
        isIsotropic: false,
        isIsotropicDisabled: function () {
            return this.isFullRemoval ?? false;
        },
        isFullRemoval: false,
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
        sidewallAngleDisabled: function () {
            return (this.isIsotropic || this.isFullRemoval) ?? false;
        },
        layerLabelsToFloat: [],
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
    }
);
