import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface RIE extends BaseBlox {
    etchDepth: number | null;
    etchDepthUnit: Units | null;
    gas: string | null;
    gasFlow: string | null;
    etchRate: number | null;
    etchRateUnit: Units | null;
    etchTime: number | null;
    etchTimeUnit: Units | null;
    rfPower: number | null;
    rfPowerUnit: Units | null;
    pressure: number | null;
    pressureUnit: Units | null;
    layerLabelsToEtch: string[] | null;
    percentToEtch: number | null;
    isotropicEtch: boolean | null;
    sidewaysEtch: number | null;
    keepFloatingStructures: boolean | null;
    etchBuriedLayers: boolean | null;
    // SEMIFAB
    isIsotropic: boolean | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
    sidewallAngleDisabled: () => boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const rieDisplay: DisplayMap = {
    // Display
    layerLabelsToEtch: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Etch",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    percentToEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Etch Depth",
        order: 1,
        tabs: [Tab.DISPLAY],
        defaultValue: 100,
        stepSize: 2
    },
    sidewaysEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sideways Etch",
        order: 2,
        tabs: [Tab.DISPLAY],
        defaultValue: 0,
        stepSize: 2
    },
    isotropicEtch: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Isotropic Etch",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: false
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    etchBuriedLayers: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Etch Buried Layers",
        order: 5,
        tabs: [Tab.DISPLAY],
        defaultValue: false
    },

    // Experimental
    etchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Depth",
        placeholder: "Enter etch depth",
        isNumber: true,
        order: 6,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    etchTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Time",
        isNumber: true,
        placeholder: "Enter etch time",
        order: 7,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        tabs: [Tab.EXPERIMENTAL],
    },
    etchRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Rate",
        placeholder: "Enter etch rate",
        isNumber: true,
        order: 8,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.NMPERMIN,Units.UMPERMIN,Units.UMPERHOUR],
        tabs: [Tab.EXPERIMENTAL],
    },
    gas: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Gas Composition",
        placeholder: "e.g. He/O2",
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    gasFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Gas Flows and Ratios",
        placeholder: "e.g. 2/1 or 20/10 sccm",
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    rfPower: {
        fieldType: DisplayFieldTypes.Input,
        label: "RF Power",
        placeholder: "Enter RF power",
        isNumber: true,
        order: 11,
        units: [Units.WATT],
        tabs: [Tab.EXPERIMENTAL],
    },
    pressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 12,
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    isIsotropic: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Isotropic Etch",
        order: 3,
        tabs: [Tab.SEMIFAB],
        defaultValue: false
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 19,
        tabs: [Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
}

export const getRIE = ({stepNumber}: BloxArgs): RIE => ({
        name: `RIE ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.RIE,
        etchDepth: null,
        etchDepthUnit: Units.NM,
        gas: null,
        gasFlow: null,
        etchRate: null,
        etchRateUnit: Units.NMPERSEC,
        etchTime: null,
        etchTimeUnit: Units.SEC,
        rfPower: null,
        rfPowerUnit: Units.WATT,
        pressure: null,
        pressureUnit: Units.TORR,
        layerLabelsToEtch: [],
        percentToEtch: 100,
        isotropicEtch: false,
        sidewaysEtch: 0,
        keepFloatingStructures: false,
        etchBuriedLayers: false,
        commentField: null,
        customFields: {},
        // SEMIFAB
        isIsotropic: false,
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
        sidewallAngleDisabled: function () {
            return this.isIsotropic ?? false;
        },
        layerLabelsToFloat: [],
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
         // to do: user needs to select up to which layer to etch
});