import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface RCAClean extends BaseBlox {
    // to do user select number of cleaning steps
    cleanChemicalSC1: string | null;
    cleanTimeSC1: number | null;
    cleanTimeSC1Unit: Units | null;
    cleanTempSC1: number | null;
    cleanTempSC1Unit: Units | null;
    cleanChemicalSC2: string | null;
    cleanTimeSC2: number | null;
    cleanTimeSC2Unit: Units | null;
    cleanTempSC2: number | null;
    cleanTempSC2Unit: Units | null;
    dipHF: string | null;
    dipHFTime: number | null;
    dipHFTimeUnit: Units | null;
    dipHFTemp: number | null;
    dipHFTempUnit: Units | null;
    // SEMIFAB
    layerLabelsToRemove: string[] | null;
    keepFloatingStructures: boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const rcaCleanDisplay: DisplayMap = {
    cleanChemicalSC1: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 1 Constituents",
        placeholder: "Enter chemicals and ratios",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    cleanTimeSC1: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 1 Cleaning Time",
        placeholder: "Enter cleaning time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    cleanTempSC1: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 1 Cleaning Temperature",
        placeholder: "Enter cleaning temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    dipHF: {
        fieldType: DisplayFieldTypes.Input,
        label: "HF Concentration",
        placeholder: "Enter concentration",
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
      },
    dipHFTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "HF Dip Time",
        placeholder: "Enter time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    dipHFTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "HF Dip Temperature",
        placeholder: "Enter temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    cleanChemicalSC2: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 2 Constituents",
        placeholder: "Enter chemicals and ratios",
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
      },
    cleanTimeSC2: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 2 Cleaning Time",
        placeholder: "Enter cleaning time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    cleanTempSC2: {
        fieldType: DisplayFieldTypes.Input,
        label: "SC 2 Cleaning Temperature",
        placeholder: "Enter cleaning temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    layerLabelsToRemove: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Remove",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: "This will fully remove the selected layers even if they are buried.",
        isOptionalSemifab: true,
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 5,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 9,
        tabs: [Tab.SEMIFAB],
        isOptionalSemifab: true,
    },

}

export const getRCAClean = ({stepNumber}: BloxArgs): RCAClean => ({
        name: `RCA Clean ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.RCAClean,
        cleanChemicalSC1: "H2O:NH4OH(29%):H2O2(30%) (5:1:1)",
        cleanTimeSC1: 10,
        cleanTimeSC1Unit: Units.MIN,
        cleanTempSC1: 75,
        cleanTempSC1Unit: Units.CELSIUS,
        cleanChemicalSC2: "H2O:HCl(37%):H2O2(30%) (6:1:1)",
        cleanTimeSC2: 10,
        cleanTimeSC2Unit: Units.MIN,
        cleanTempSC2: 75,
        cleanTempSC2Unit: Units.CELSIUS,
        dipHF: "H2O:HF(49%) (50:1)",
        dipHFTime: 15,
        dipHFTimeUnit: Units.SEC,
        dipHFTemp: 20,
        dipHFTempUnit: Units.CELSIUS,
        commentField: null,
        customFields: {},
        // semifab
        layerLabelsToRemove: [],
        layerLabelsToFloat: [],
        keepFloatingStructures: false,
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
    }
);
