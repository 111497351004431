import Button from "@mui/material/Button";
import PlayArrow from "@mui/icons-material/PlayArrow";
import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Container } from '@mui/material';
import { fabutheme } from "../MaterialUI/muicolortheme";
// import { ArrowsDown } from '../components/ArrowsDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const getRandomDelay = () => {
  const min = 0;
  const max = 2; // seconds
  return `${Math.random() * (max - min) + min}s`;
};

export const WelcomeBox: React.FC = () => {

  const handleScroll = () => {
    const nextElement = document.getElementById('carousel'); // Adjust the ID to the next section's ID
    if (nextElement) {
      nextElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={fabutheme}>
        <div id="welcome-box-container" className="tw-min-h-[94svh] tw-animate-fadeIn tw-relative tw-justify-center">
          <Container maxWidth="xl">
            <div id="welcome-box" className="tw-flex tw-justify-center tw-relative tw-items-center tw-transition-opacity tw-duration-300 tw-ease-out tw-mb-16 lg:tw-mb-20 xl:tw-mb-24">            <div className="-tw-z-10 tw-rounded-full tw-bg-white tw-blur-3xl tw-opacity-75 tw-absolute tw-align-top tw-h-[24rem] md:tw-h-[24rem] md:tw-w-[34rem] lg:tw-h-[24rem] lg:tw-w-[38rem] xl:tw-h-[28rem] xl:tw-w-[50rem]"></div>
              <div className="tw-flex tw-flex-col tw-z-0 lg:tw-flex-row tw-items-start lg:tw-items-center lg:tw-space-x-8">
                <div className="tw-flex-1 tw-my-16 tw-mx-2 lg:tw-mx-24">
                  <div className="tw-text-center">
                    <p className="tw-text-sm tw-tracking-[0.5em] tw-underline tw-text-transparent tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299] tw-to-[#ff9567] tw-bg-clip-text">
                      WELCOME TO
                    </p>
                    <div className="tw-text-7xl tw-flex tw-justify-center sm:tw-text-7xl md:tw-text-8xl lg:tw-text-9xl xl:tw-text-9xl tw-font-black tw-drop-shadow-[] tw-animation-delay-200">
                      <h2>Fab</h2>
                      <h2 className="tw-text-transparent tw-bg-gradient-to-r tw-from-[#8E4CA8] tw-via-[#d35299] tw-to-[#ff9567] tw-bg-clip-text">
                        u
                      </h2>
                      <h2>Blox</h2>
                    </div>
                    <div className=" tw-text-lg sm:tw-text-lg md:tw-text-2xl lg:tw-text-3xl xl:tw-text-3xl tw-font-light tw-my-2 tw-break-keep tw-drop-shadow-2xl ">
                      <p>
                        A <span className="tw-font-medium">state-of-the-art</span> design & data management<br/>
                        tool for <span className="tw-font-medium">micro & nano fabrication</span> processes.
                      </p>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 tw-mt-4 tw-drop-shadow-sm tw-animate-fadeIn-800">
                    <div>
                      <a href={"/process-editor/create"} >
                        <Button variant="contained" size="medium">
                          Start Designing
                        </Button>
                      </a>
                    </div>
                    <div>
                      <a href={"https://www.youtube.com/embed/TYXCizU-iNs?controls=0"} target='_blank' className="hover:tw-drop-shadow-2xl tw-animate-fadeIn-800">
                        <Button variant="outlined" size="medium" endIcon={<PlayArrow />} className="tw-bg-white">
                          Watch Demo
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <div className="tw-absolute tw-bottom-0 tw-w-full tw-h-24 tw-overflow-hidden tw-bg-gradient-to-t tw-from-white tw-via-white">
            <div className="tw-bottom-5">
              <div className="tw-grid tw-gap-1" style={{ gridTemplateColumns: 'repeat(auto-fill, 32px)', gridTemplateRows: 'repeat(4, 24px)' }}>
                {Array.from({ length: 4 * 200 }).map((_, index) => (
                  <KeyboardArrowDownIcon
                    key={index}
                    className="tw-m-auto tw-text-4xl tw-text-[#d35299] tw-animate-customPulse hover:tw-animate-spin hover:tw-text-[#ff9567] tw-overflow-clip cursor-pointer"
                    style={{ animationDelay: getRandomDelay() }}
                    onClick={handleScroll}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
