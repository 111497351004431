import { createTheme } from '@mui/material/styles';
// import { Palette, PaletteOptions } from '@mui/material/styles/createPalette';

// Extend the Palette and PaletteOptions interfaces
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    lightblue: Palette['primary'];
  }
  interface PaletteOptions {
    lightblue?: PaletteOptions['primary'];
  }
}

export const fabutheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#8E4CA8',
    },
    secondary: {
      main: '#d35299',
    },
    error: {
      main: '#ff6b80',
    },
    warning: {
      main: '#ff9567',
    },
    lightblue: {
      main: '#DEE2FB',
    },
  },
});