import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MultistepDialog, DialogStep, DialogBody, Button, Classes, Intent, ContextMenu, Menu, MenuItem, Callout, ButtonGroup, Icon, Colors } from '@blueprintjs/core';
import { useFetchSemifabJSON } from '../hooks/DataFetching/use-fetch-semifab-svg';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { Loading } from '../components/Loading';
import { displayMap } from '../Data/display-mappings';
import { Tab } from '../Data/enums';
import { getProperty, hasKey } from '../utils/ts-helpers';
import { Column, Row } from '../Layout/layouts';
import { IconNames } from '@blueprintjs/icons';
import { makeSemifabPpt } from '../utils/export-semifab-ppt';
import { ContactDialog } from './ContactDialog';
import { envelopeIcon } from '../components/PageHeader';
import { BloxTypes } from '../Data/BloxSchema/base-blox';
import { useReadUser, useUpdateUser } from '../hooks/DataFetching/use-fetch-user';
import { useAuth0 } from '@auth0/auth0-react';
import { LabeledSwitch } from '../components/Fields/LabeledSwitch';
import { SemifabExtrudeDialogContent } from './SemifabSetupDialog';

interface SemifabDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const SemifabDialog: React.FC<SemifabDialogProps> = ({ isOpen, setIsOpen }) => {
    const [processBloxes,] = useFabuState('processBloxes');
    const [processId,] = useFabuState('processId');
    const [processName,] = useFabuState('processName');
    const [processSections,] = useFabuState('processSections');
    const [svgsResult, setSvgsResult] = useState<{ [key: string]: string } | null>(null);
    const [legend, setLegend] = useState("");
    const [resultError, setResultError] = useState<string | null>(null);
    const [isCustomLoading, setIsCustomLoading] = useState(true);
    const { mutate: fetchSvgs, isLoading: isSvgsLoading } = useFetchSemifabJSON();
    const { user } = useAuth0();
    const [show3d, setShow3d] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsCustomLoading(false);
        }, 5000);
    }, [])


    const handleClose = () => {
        setIsOpen(false);
        setSvgsResult(null);
        setResultError(null);
        setLegend("");
    };

    const bloxesInfoMemo = useMemo(() => {
        if (!processBloxes || processBloxes.length <= 1) return [];
        if (processBloxes.length > 0) {
            const startBlox = processBloxes[0];
            startBlox.simulate3D = show3d;
        }
        return processBloxes;
    }, [processBloxes, show3d]);

    const missingInfoEl = useMemo(() => {
        if (!processBloxes) return null;
        const missingInfo: { name: string, missingValues: string[] }[] = [];

        processBloxes.forEach((blox, index) => {
            const missingValues = [];
            const bloxDisplay = displayMap[blox.bloxType];

            for (const key in bloxDisplay) {
                const { tabs, label, isOptionalSemifab } = bloxDisplay[key];

                if (!tabs.includes(Tab.SEMIFAB) || isOptionalSemifab) {
                    continue;
                }

                if (!hasKey(blox, key)) {
                    if (label === undefined)
                        continue;

                    missingValues.push(label);
                    continue;
                }

                // Ignore if disabled
                let disabled = false
                const disableName = `${key}Disabled` ?? false;
                if (hasKey(blox, disableName) && typeof blox[disableName] === 'function'){
                    const func = blox[disableName] as (() => boolean | null);
                    disabled = func.call(blox) ?? false;
                }
                if (disabled){
                    continue;
                }

                const value = getProperty(blox, key);
                if ((value === undefined || value === null) && label !== undefined) {
                    missingValues.push(label);
                    continue;
                }
                if (Array.isArray(value) && value.length === 0 && label !== undefined){
                    missingValues.push(label);
                }
            }

            // special handling for startblox
            if (blox.bloxType === BloxTypes.StartBlox) {
                const layers = getProperty(blox , "layers");
                if (layers) {
                    for (let index = 0; index < layers.length; index++) {
                        const lay = layers[index];
                        const layerName = lay.layerLabel ?? `Layer ${index}`

                        if (!lay.layerSimulationThickness){
                            missingValues.push(layerName + ' Thickness' );
                        }
                        if (!lay.layerSimulationThicknessUnit){
                            missingValues.push(layerName + ' Unit');
                        }
                    }
                }
            }

            if (missingValues.length > 0) {
                missingInfo.push({
                    name: `(#${index + 1}) ${blox.name}`,
                    missingValues
                });
            }
        });

        if (missingInfo.length === 0) return null;

        return (
            <DialogStep
                key="missingValuesStep"
                id="missingValuesStep"
                title="Missing Information"
                nextButtonProps={{ text: 'Next', disabled: true }}
                panel={
                    <DialogBody>
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            <p>Close the dialog and fill in missing fields in the right panel before continuing</p>
                            {missingInfo.map((info, index) => (
                                <div key={index}>
                                    <strong>{info.name}</strong>: {info.missingValues.join(', ')}
                                </div>
                            ))}
                        </div>
                    </DialogBody>
                }
            />
        );
    }, [processBloxes]);

    const updateUserMutation = useUpdateUser();
    const fabuUser = useReadUser(user?.sub).data;

    const multiStepDialogChangeCallback = useCallback((newDialogStepId: string, prevDialogStepId: string | undefined) => {

        const handleConfirmJson = () => {
            const requestData = {
                bloxesInfo: bloxesInfoMemo,
                processId: processId
            };
            fetchSvgs(requestData, {
                onSuccess: (data) => {
                    const { svgs, legend } = data;
                    setSvgsResult(svgs);
                    setLegend(legend ?? "");

                    if (fabuUser === undefined) return;
                    updateUserMutation.mutate({ 
                        userId: user?.sub ?? "", 
                        data: { metrics: {
                            ...fabuUser.metrics,
                            simulateCount: (fabuUser.metrics?.simulateCount ?? 0) + 1
                        }} 
                    });
                },
                onError: (e) => {
                    setResultError(e.message);
                }
            });
        };

        if (newDialogStepId === 'multi_step' && svgsResult === null && !isSvgsLoading) {
            handleConfirmJson();
        }
    }, [bloxesInfoMemo, fetchSvgs, isSvgsLoading, svgsResult, processId, fabuUser, updateUserMutation, user?.sub]);

    const svgsResultRef = useRef<HTMLDivElement>(null);

    const scrollTo = (direction: 'top' | 'bottom') => {
        if (svgsResultRef.current) {
            svgsResultRef.current.scroll({
                top: direction === 'top' ? 0 : svgsResultRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };



    
    const copyImageToClipboard = async (svgContent: string) => {
        const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);

        const img = new Image();
        img.src = url;
        await img.decode();  // Ensure the image is loaded

        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(img, 0, 0);
            canvas.toBlob(async (blob) => {
                try {
                    if (blob) {
                        await navigator.clipboard.write([
                            new ClipboardItem({ 'image/png': blob })
                        ]);
                    }
                } catch (err) {
                    console.error('Failed to copy image:', err);
                }
            }, 'image/png');
        }

        URL.revokeObjectURL(url);
    };

    function legendHandleDownloadClick() {
        const legendSvgBlob = new Blob([legend], { type: 'image/svg+xml;charset=utf-8' });
        const legendDownloadUrl = URL.createObjectURL(legendSvgBlob);
        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = legendDownloadUrl;
        link.download = 'legend.svg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(legendDownloadUrl); // Clean up the URL object
    }

    return (
        <MultistepDialog
            style={{ minWidth: '820px', maxHeight: '800px' }}
            isOpen={isOpen}
            onClose={handleClose}
            title="StackSimulator"
            onChange={multiStepDialogChangeCallback}
            nextButtonProps={{ intent: 'primary' }}
            showCloseButtonInFooter={true}
            finalButtonProps={{ intent: 'primary', text: 'Close', onClick: handleClose, disabled: false }}
        >
            <DialogStep
                id="info"
                title="Info"
                panel={<DialogBody>
                    <div style={{ minHeight: '300px' }}>
                        <p>The new <b>FabuBlox StackSimulator</b> generates dimensionally accurate cross-sections.</p>
                        <Row>
                            <Icon intent={Intent.DANGER} icon={IconNames.WarningSign} style={{ paddingRight: '10px' }} />
                            <p>Calculating layer stacks to scale requires stringent parameter inputs.<br/> All parameter fields in the new <b>Simulate</b> tab in the right panel need to be filled out to predict cross-sections using StackSimulator.</p>
                        </Row>
                        <br/>
                        <Callout intent={Intent.PRIMARY} title={"Beta Testing Phase"}>
                            <>
                                <p>The FabuBlox team is gathering feedback on this beta release of StackSimulator. </p>
                                <p>Please report any issues and reach out with feedback, questions, or suggestions using the mail icon or email us at <a href="mailto:hello@fabublox.com">hello@fabublox.com</a>. </p>
                                <p>In this beta testing stage, usage of StackSimulator is unlimited and free! We will contact all beta testers to offer future discounted use for participation in one-on-on feedback sessions.</p>
                            </>
                        </Callout>
                        <br/>
                        {!missingInfoEl && <><p>Press <b>Next</b> to simulate fabricated cross-sections for this process. </p>
                            <div style={{width: '150px'}}><LabeledSwitch
                        label = "Extrude 3D"
                        infoContent = {SemifabExtrudeDialogContent}
                        onChange={() => setShow3d(!show3d)}
                        checked = {show3d}
                    /></div>
                        </>}
                        {missingInfoEl && <p style={{color: Colors.RED3}}>Some parameter fields are missing and need to be filled out before simulating.
                        <br/>Press <b>Next</b> to see which fields are missing.</p>}
                        
                    </div>
                </DialogBody>}
            />
            {(missingInfoEl !== null) && missingInfoEl}
            <DialogStep
                id="multi_step"
                title="Step Results"
                panel={
                    <>
                        {resultError ? (
                            <DialogBody>
                                <p>{resultError}</p>
                            </DialogBody>
                        ) : (
                            <div ref={svgsResultRef} style={{ height: '700px', overflowY: 'auto' }}>
                                <div className={Classes.DIALOG_BODY}>
                                    {svgsResult && !isCustomLoading ? (
                                        <div style={{ minHeight: '300px' }}>
                                            <Callout intent={Intent.PRIMARY} title="Right-click to download or copy results" />
                                            <div style={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
                                                <div style={{ width: 'calc(100% - 180px)', marginRight: '20px' }}>
                                                    {Object.entries(svgsResult).map(([_name, svgContent], index) => {
                                                        const correspondingBlox = processBloxes[index];
                                                        if (!correspondingBlox) {
                                                            setResultError('An error occurred. Refresh the page and try again. For support, click the mail icon in the top left of the page.');
                                                            return <span>An error occurred</span>
                                                        }
                                                        const bloxName = correspondingBlox.name;
                                                        const stepNumber = index + 1;
                                                        const heading = `Step ${stepNumber}: ${bloxName}`;
                                                        const fileName = `fabublox_${stepNumber}_${bloxName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.svg`;

                                                        const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
                                                        const downloadUrl = URL.createObjectURL(svgBlob);
                                                        const handleDownloadClick = () => {
                                                            // Create a temporary link to trigger the download
                                                            const link = document.createElement('a');
                                                            link.href = downloadUrl;
                                                            link.download = fileName;
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                            URL.revokeObjectURL(downloadUrl); // Clean up the URL object
                                                        };

                                                        return (
                                                            <div key={`${index}-${heading}`} style={{ marginBottom: '50px'}}>
                                                                <h3>{heading}</h3>
                                                                <ContextMenu
                                                                    key={`${index}-${heading}-context-menu`}
                                                                    content={
                                                                        <Menu key={`${index}-${heading}-menu`}>
                                                                            <MenuItem key={`${index}-${heading}-menu-clipboard`} icon={IconNames.Clipboard} text="Copy to Clipboard" onClick={() => copyImageToClipboard(svgContent)} />
                                                                            <MenuItem key={`${index}-${heading}-menu-download`} icon={IconNames.Download} text="Download" onClick={handleDownloadClick} />
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <img
                                                                        key={`${index}-${heading}`}
                                                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}`}
                                                                        style={{ cursor: 'context-menu', width: '400px' }}
                                                                        alt={heading} />
                                                                </ContextMenu>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div style={{ position: 'sticky', top: '0px', marginRight: '10px' }}>
                                                    <Row>
                                                        <div className='jump-to-button'>
                                                            <ButtonGroup >
                                                                <Button
                                                                    icon={IconNames.ArrowUp}
                                                                    onClick={scrollTo.bind(null, 'top')}
                                                                />
                                                                <Button
                                                                    icon={IconNames.ArrowDown}
                                                                    onClick={scrollTo.bind(null, 'bottom')}
                                                                />
                                                            </ButtonGroup>
                                                        </div>
                                                    </Row>

                                                    <div>
                                                        <h3>Legend</h3>
                                                        <ContextMenu
                                                            content={
                                                                <Menu key="legend-menu">
                                                                    <MenuItem icon={IconNames.Clipboard} text="Copy to Clipboard" onClick={() => copyImageToClipboard(legend)} />
                                                                    <MenuItem icon={IconNames.Download} text="Download" onClick={legendHandleDownloadClick} />
                                                                </Menu>
                                                            }
                                                        >
                                                            <img
                                                                src={`data:image/svg+xml;utf8,${encodeURIComponent(legend)}`}
                                                                style={{ cursor: 'context-menu', minWidth:'100px', maxWidth: '170px',  maxHeight: '500px', height: 'auto', width: 'auto'}}
                                                                alt="Legend" />
                                                        </ContextMenu>
                                                    </div>
                                                </div>
                                            </div>
                                            <Column style={{position:'sticky', float:'right', bottom:'10px', right:'10px', margin: 'auto'}}>
                                            <ContactDialog messagePlaceholder='Please include your processId in the message...'>
                                                    {(handleOpen: () => void) => (
                                                        <Button 
                                                            large={true} 
                                                            icon={envelopeIcon} 
                                                            onClick={handleOpen} 
                                                            style={{marginLeft: 'auto', marginBottom: '5px'}} 
                                                            />
                                                    )}
                                            </ContactDialog>
                                            <Button
                                                style={{position:'sticky', float:'right', bottom:'10px', right:'10px', margin: 'auto', color:'white', backgroundColor: Colors.ORANGE3}}
                                                intent={Intent.PRIMARY}
                                                large={true}
                                                text='Download PPT'
                                                onClick={() => makeSemifabPpt(svgsResult, processBloxes, legend, processName, processSections, updateUserMutation, fabuUser)}
                                            />
                                            </Column>
                                            
                                                
                                        </div>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                }
            />
        </MultistepDialog>
    );
};

export default SemifabDialog;
