import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import { Column } from '../Layout/layouts';
import { Footer } from '../components/Footer';


import GridBackground from '../components/GridBackground';

import TOSContent from '../components/TermsOfService';


export const TermsOfService: React.FC = () => {
  return (
    <Column className={'searchContainer'}>
      <GridBackground />
      <TOSContent />
      <Footer /> 
    </Column>
  );
};
