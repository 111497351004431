import { ALDDisplay } from "./BloxSchema/ald";
import { apCVDDisplay } from "./BloxSchema/apcvd";
import { bakeDisplay } from "./BloxSchema/bake";
import { BloxTypes } from "./BloxSchema/base-blox";
import { CMPDisplay } from "./BloxSchema/cmp";
import { customBloxDisplay } from "./BloxSchema/customblox";
import { customCleanDisplay } from "./BloxSchema/customclean";
import { developDisplay } from "./BloxSchema/develop";
import { directWriteLithoDisplay } from "./BloxSchema/direct-write-litho";
import { DopeDiffusionDisplay } from "./BloxSchema/doping-diffusion";
import { dropCastDisplay } from "./BloxSchema/drop-cast";
import { ebeamLithoDisplay } from "./BloxSchema/ebeam-litho";
import { eBeamEvapDisplay } from "./BloxSchema/ebeamevap";
import { electroplateDisplay } from "./BloxSchema/electroplate";
import { flipStackDisplay } from "./BloxSchema/flipstack";
import { HMDSVaporDisplay } from "./BloxSchema/HMDS-vapor";
import { icpRIEDisplay } from "./BloxSchema/icp-rie";
import { imprintLithoDisplay } from "./BloxSchema/imprint-litho";
import { interferenceLithoDisplay } from "./BloxSchema/interference-litho";
import { ionImplantationDisplay } from "./BloxSchema/ion-implantation";
import { ionMillingDisplay } from "./BloxSchema/ion-milling";
import { liftoffDisplay } from "./BloxSchema/liftoff";
import { lpCVDDisplay } from "./BloxSchema/lpcvd";
import { maskAlignDisplay } from "./BloxSchema/mask-align";
import { mbeDisplay } from "./BloxSchema/mbe";
import { moCVDDisplay } from "./BloxSchema/mocvd";
import { peCVDDisplay } from "./BloxSchema/pecvd";
import { plasmaAshDisplay } from "./BloxSchema/plasma-ash";
import { plasmaDescumDisplay } from "./BloxSchema/plasma-descum";
import { rcaCleanDisplay } from "./BloxSchema/rcaclean";
import { rieDisplay } from "./BloxSchema/rie";
import { RTADisplay } from "./BloxSchema/rta";
import { spinCoatDisplay } from "./BloxSchema/spin-coat";
import { spinDopantDisplay } from "./BloxSchema/spin-coat-dopant";
import { spinCoatResistDisplay } from "./BloxSchema/spin-coat-resist";
import { sputterDisplay } from "./BloxSchema/sputter";
import { startBloxDisplay } from "./BloxSchema/start-blox";
import { stepperLithoDisplay } from "./BloxSchema/stepper-litho";
import { stripResistDisplay } from "./BloxSchema/strip-resist";
import { substrateStackDisplay } from "./BloxSchema/substrate-stack";
import { thermalOxideDisplay } from "./BloxSchema/thermal-oxide";
import { thermalEvapEvapDisplay } from "./BloxSchema/thermalevap";
import { WaferBondingDisplay } from "./BloxSchema/wafer-bonding";
import { WetGrowthDisplay } from "./BloxSchema/wet-growth";
import { wetetchDisplay } from "./BloxSchema/wetetch";
import { DisplayFieldTypes, Tab, Units } from "./enums";

export interface DisplayMap {
    [key: string]: DisplayParameters
}

export interface DisplayParameters {
    fieldType: DisplayFieldTypes //refactor as enum
    label?: string;
    placeholder?: string;
    isNumber?: boolean;
    defaultValue?: any;
    options?: string[]; //options for Dropdown field
    order: number; // display parameters sorted by order
    units?: Units[]; // options of Units to display in dropdown
    tabs: Tab[];
    stepSize?: number; // for percentage slider
    materialFilter?: string;
    maxSliderValue? : number;
    infoContent?: string | JSX.Element; //shows info icon with tooltip or dialog
    isOptionalSemifab?: boolean; // Optional semifab param
    isDisabled?:  () => boolean; 
}

export const displayMap: {[key in BloxTypes]: DisplayMap} = {
    "SUBSTACK": substrateStackDisplay,
    "STARTBLOX": startBloxDisplay,
    "CUSTOMBLOX": customBloxDisplay,
    "CUSTOMCLEAN": customCleanDisplay,
    "RCACLEAN": rcaCleanDisplay,
    "SPINCOAT": spinCoatDisplay,
    "SPINCOATRESIST": spinCoatResistDisplay,
    "SPINDOPANT": spinDopantDisplay,
    "BAKE": bakeDisplay,
    "DEVELOP": developDisplay,
    "MASKALIGN": maskAlignDisplay,
    "STEPPERLITHO": stepperLithoDisplay,
    "DIRECTWRITELITHO": directWriteLithoDisplay,
    "INTERFERENCE-LITHO": interferenceLithoDisplay,
    "IMPRINTLITHO": imprintLithoDisplay,
    "EBL": ebeamLithoDisplay,
    "ICPRIE": icpRIEDisplay,
    "RIE": rieDisplay,
    "IONMILL": ionMillingDisplay,
    "CMP": CMPDisplay,
    "PLASMA-ASH": plasmaAshDisplay,
    "PLASMA-DESCUM": plasmaDescumDisplay,
    "HMDSVAPOR": HMDSVaporDisplay,
    "DROPCAST": dropCastDisplay,
    "WETGROWTH": WetGrowthDisplay,
    "SPUTTER": sputterDisplay,
    "WETETCH": wetetchDisplay,
    "FLIPSTACK": flipStackDisplay,
    "MOCVD": moCVDDisplay,
    "MBE": mbeDisplay,
    "ALD": ALDDisplay,
    "PECVD": peCVDDisplay,
    "LPCVD": lpCVDDisplay,
    "APCVD": apCVDDisplay,

    "EBEAMEVAP": eBeamEvapDisplay,
    "THERMALEVAP": thermalEvapEvapDisplay,
    "THERMALOXIDATION": thermalOxideDisplay,
    "ELECTROPLATE": electroplateDisplay,
    "LIFTOFF": liftoffDisplay,
    "STRIPRESIST": stripResistDisplay,
    "IONIMPLANT": ionImplantationDisplay,
    "DOPEDIFFUSION": DopeDiffusionDisplay,
    "RTA": RTADisplay,
    "WAFERBONDING": WaferBondingDisplay
}

export const commentDisplayParams: DisplayParameters = {
    fieldType: DisplayFieldTypes.Multiline,
    label: "Comments",
    order: 99,
    tabs: [Tab.EXPERIMENTAL]
}

export const layerDisplayParams: DisplayMap = {    
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY]
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
    },
    layerSimulationThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Thickness",
        order: 2,
        isNumber: true,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
        units: [Units.NM, Units.MICRON],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        placeholder: "Insert layer name...",
        order: 0,
        tabs: [Tab.DISPLAY],
    },
    showLabel: {
        fieldType: DisplayFieldTypes.Switch,
        order: 1,
        tabs: [Tab.DISPLAY],
        defaultValue: true
    }
}